import ReactQuill from "react-quill";
import styled from "styled-components";

export const Wrapper = styled.div`
  font-size: 2.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.7rem;
  width: ${(props) => (props.width ? props.width : "calc(50% - 1rem)")};
  .ql-editor {
    font-size: 1.6rem;
  }

  .ql-toolbar.ql-snow {
    background: #fff;
    border: none;
  }
`;

export const Rectangle = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  background-color: black;
  box-sizing: border-box;
`;

export const Title = styled.input`
  border: none;
  outline: none;
  width: 100%;
  font-size: 1.6rem;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
`;

export const Category = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const SmartEditorBox = styled(ReactQuill)`
  height: 32rem;
  font-size: 1.6rem;
  font-size: 2.5rem;
  background: #fff;
`;
