import { PLACEHOLDER } from "constants/baseStyle";
import { POST_SKETCH_BACK_FETCH } from "constants/pathConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { useEffect, useState } from "react";
import { Wrapper } from "template/ListTemplate.style";
import { Search } from "template/SearchTemplate.style";

const SketchBackSearch = ({
  setSketchBackRes,
  setSearchReq,
  currentPage,
  setCurrentPage,
  setIsSearch,
  searchReq,
  setSketchBackDetailRes,
  setSearchDetailReq,
}) => {
  const toast = useToastCustom();

  const [walletAddress, setWalletAddress] = useState("");
  const [ebitmallOrderId, setEbitmallOrderId] = useState("");
  const [excludeYn, setExcludeYn] = useState("N");
  const [amountFrom, setAmountFrom] = useState(0);
  const [amountTo, setAmountTo] = useState(10000000000);

  useEffect(() => {
    if (!Object.keys(searchReq)[0]) return;
    fetchData();
  }, [currentPage]);

  const { mutate } = useMutationCustom(POST_SKETCH_BACK_FETCH);

  const fetchData = () => {
    const data = {
      walletAddress,
      ebitmallOrderId,
      excludeYn,
      amountFrom,
      amountTo: amountTo || 10000000000,
      currentPage,
      pageSize: 6,
    };

    setSearchReq(data);

    mutate(data, {
      onSuccess: (res) => {
        setSketchBackRes(res);
        setSearchDetailReq({});
      },
      onError: () => {
        toast("조회에 실패했습니다.", "error");
      },
    });
  };

  const searchHandler = () => {
    fetchData();
    setIsSearch(true);
    setCurrentPage(1);
    setSketchBackDetailRes([]);
  };

  return (
    <Wrapper>
      <Search.Text>스케치 백 조회</Search.Text>

      <Search.Container>
        <Search.Content>
          <Search.InputWrapper>
            <div>지갑주소</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => {
                setWalletAddress(e.target.value);
              }}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            <div>주문번호</div>
            <Search.Input
              placeholder={PLACEHOLDER.ALL}
              onChange={(e) => {
                setEbitmallOrderId(e.target.value);
              }}
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            <div>금액(부터)</div>
            <Search.Input
              placeholder="숫자만 입력"
              onChange={(e) => {
                setAmountFrom(e.target.value);
              }}
              type="number"
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            <div>금액(까지)</div>
            <Search.Input
              placeholder="숫자만 입력"
              onChange={(e) => {
                setAmountTo(e.target.value);
              }}
              type="number"
            />
          </Search.InputWrapper>

          <Search.InputWrapper>
            <div>스케치백 대상</div>
            <Search.Select onChange={(e) => setExcludeYn(e.target.value)}>
              <Search.Option value="N">{"대상만"}</Search.Option>
              <Search.Option value="Y">{"제외만"}</Search.Option>
            </Search.Select>
          </Search.InputWrapper>
        </Search.Content>

        <Search.Btn onClick={searchHandler}>조회</Search.Btn>
      </Search.Container>
    </Wrapper>
  );
};

export default SketchBackSearch;
