import { Category } from "../style";

const ReleaseCategories = ({
  categoryPlatform,
  setSelectedPlatform,
  selectedPlatform,
  categoryType,
  setSelectedType,
  setReleaseInfoId,
  selectedType,
}) => {
  return (
    <Category.Box>
      <Category.Btn>
        {categoryPlatform.list?.map((v) => (
          <Category.List
            key={v.name}
            value={v.name}
            onClick={() => {
              setSelectedPlatform(v.name);
            }}
            selected={v.name === selectedPlatform}
          >
            <li>{v.name}</li>
          </Category.List>
        ))}
      </Category.Btn>
      <Category.Btn>
        {categoryType[0]?.info?.map((v) => (
          <Category.List
            key={v.type}
            value={v.type}
            onClick={() => {
              setSelectedType(v.type);
              setReleaseInfoId(v.id);
            }}
            selected={v.type === selectedType}
          >
            {v.type}
          </Category.List>
        ))}
      </Category.Btn>
    </Category.Box>
  );
};

export default ReleaseCategories;
