import SmartEditor from "components/SmartEditor/SmartEditor";
import { Footer, Option, Title, Wrapper } from "./style";
import { useEffect, useState } from "react";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  POST_RELEASE_CATEGORY_INFO,
  POST_RELEASE_NOTE_CREATE,
  POST_RELEASE_NOTE_MODIFY,
} from "constants/pathConstant";
import { useToastCustom } from "hooks/useToastCustom";
import { useNavigationCustom } from "hooks/useNavigationCustom";
import ReleaseNoteModal from "components/Modal/ReleaseNoteModal";

const ReleaseNoteUpload = ({ modifyData }) => {
  const toast = useToastCustom();
  const nav = useNavigationCustom();

  const [selectedPlatform, setSelectedPlatform] = useState("HomePage");
  const [selectedType, setSelectedType] = useState("WEB");
  const [categoryType, setCategoryType] = useState([]);
  const [categoryPlatform, setCategoryPlatform] = useState([]);

  const [releaseInfoId, setReleaseInfoId] = useState("");
  const [version, setVersion] = useState("");
  const [versionIos, setVersionIos] = useState("");
  const [commitHash, setCommitHash] = useState("");
  const [content, setContent] = useState("");
  const [useYn, setUseYn] = useState(true);
  const [hashTag, setHashTag] = useState("");
  const [hashTags, setHashTags] = useState([]);

  const [previewData, setPreviewData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const [releaseNoteId, setReleaseNoteId] = useState("");
  useEffect(() => {
    if (!modifyData) return;
    setReleaseNoteId(modifyData.id);
    setVersion(modifyData.version);
    setVersionIos(modifyData.versionIos);
    setCommitHash(modifyData.commitHash);
    setContent(modifyData.content);
    setHashTags(modifyData.hashTags);
    setUseYn(modifyData.useYn);
  }, []);

  useEffect(() => {
    handleCategoryInfo();
  }, [selectedPlatform]);

  const { mutate: mutateFetchCategory } = useMutationCustom(
    POST_RELEASE_CATEGORY_INFO
  );

  const handleCategoryInfo = () => {
    mutateFetchCategory(
      {},
      {
        onSuccess: (res) => {
          const categoryType = res.list.filter(
            (v) => v.name === selectedPlatform
          );

          const newType = categoryType[0]?.info[0].type;
          const newId = categoryType[0]?.info[0].id;

          setCategoryType(categoryType);
          setSelectedType(newType);
          setReleaseInfoId(newId);
          setCategoryPlatform(res);
        },
      }
    );
  };

  const { mutate: mutateCreate } = useMutationCustom(POST_RELEASE_NOTE_CREATE);
  const { mutate: mutateModify } = useMutationCustom(POST_RELEASE_NOTE_MODIFY);

  const uploadHandler = () => {
    if (!version) return toast("기본 버전을 입력해주세요.", "error");
    const data = {
      version,
      versionIos,
      commitHash,
      content,
      useYn,
      ...(!modifyData && { releaseInfoId }),
      ...(modifyData && { releaseNoteId }),
      hashTags,
    };

    setPreviewData(data);

    if (modifyData) {
      mutateModify(data, {
        onSuccess: () => {
          nav("/super/system/release");
          toast("정상적으로 수정되었습니다.", "success");
        },
        onError: () => {
          toast("등록에 실패하였습니다.", "error");
        },
      });
    } else {
      mutateCreate(data, {
        onSuccess: () => {
          nav("/super/system/release");
          toast("정상적으로 등록되었습니다.", "success");
        },
        onError: () => {
          toast("등록에 실패하였습니다.", "error");
        },
      });
    }
  };

  const previewHandler = () => {
    const data = {
      releaseInfoId,
      version,
      versionIos,
      commitHash,
      content,
      useYn,
      hashTags,
      updatedAt: modifyData?.updatedAt || "입력 날짜",
    };

    setPreviewData(data);
    setIsOpen(true);
  };

  const handleHashTag = (e) => {
    if (e.key === "Enter" && hashTags.includes(hashTag)) {
      setHashTag("");
    }
    if (
      e.key === "Enter" &&
      hashTag.trim() !== "" &&
      !hashTags.includes(hashTag)
    ) {
      setHashTags([...hashTags, hashTag]);
      setHashTag("");
    }
  };

  const deleteHashTag = (deleteTag) => {
    setHashTags(hashTags.filter((tag) => tag !== deleteTag));
  };

  return (
    <Wrapper>
      <div>
        <Title>릴리즈 노트 작성하기</Title>

        <Option.Container>
          <Option.Box>
            {!modifyData && (
              <>
                <Option.DetailBox>
                  <p>플랫폼</p>
                  <Option.Select
                    onChange={(e) => setSelectedPlatform(e.target.value)}
                  >
                    {categoryPlatform.list?.map((v) => (
                      <option>{v.name}</option>
                    ))}
                  </Option.Select>
                </Option.DetailBox>
                <Option.DetailBox>
                  <p>구분</p>
                  <Option.Select
                    onChange={(e) => setReleaseInfoId(e.target.value)}
                  >
                    {categoryType[0]?.info?.map((v) => (
                      <option value={v.id}>{v.type}</option>
                    ))}
                  </Option.Select>
                </Option.DetailBox>
              </>
            )}
            <Option.DetailBox>
              <p>사용여부</p>
              <Option.Select
                value={useYn}
                onChange={(e) => setUseYn(e.target.value)}
              >
                <option value={true}>사용함</option>
                <option value={false}>사용안함</option>
              </Option.Select>
            </Option.DetailBox>
          </Option.Box>

          <Option.Box>
            <Option.DetailBox>
              <p>기본 버전</p>
              <Option.Input
                placeholder="1.1.0"
                value={version}
                onChange={(e) => setVersion(e.target.value)}
              />
            </Option.DetailBox>
            <Option.DetailBox>
              <p>IOS 버전</p>
              <Option.Input
                placeholder="0.9.2"
                value={versionIos}
                onChange={(e) => setVersionIos(e.target.value)}
              />
            </Option.DetailBox>
            <Option.DetailBox>
              <p>Git 해시코드</p>
              <Option.Input
                placeholder="a1b2c3d4"
                value={commitHash}
                onChange={(e) => setCommitHash(e.target.value)}
              />
            </Option.DetailBox>
          </Option.Box>

          <Option.Box>
            <Option.DetailBox>
              <p>해시태그</p>
              <Option.Input
                placeholder="입력 후 엔터"
                onChange={(e) => setHashTag(e.target.value)}
                onKeyUp={(e) => handleHashTag(e)}
                value={hashTag}
              />
              <Option.HashTag>
                {hashTags.map((v) => (
                  <div>
                    <p>{v}</p>
                    <div onClick={() => deleteHashTag(v)}>
                      <img
                        src="https://cdn.sketchproject.io/file/149aa92btag_close_btn.svg"
                        alt="delete_btn"
                      />
                    </div>
                  </div>
                ))}
              </Option.HashTag>
            </Option.DetailBox>
          </Option.Box>
        </Option.Container>

        <SmartEditor
          category={"내용"}
          setMessage={setContent}
          messageValue={content}
          toolbarId={"releaseNoteKr"}
          width={"100%"}
        />

        <Footer.Container>
          <Footer.Btn onClick={() => nav("/super/system/release")}>
            목록
          </Footer.Btn>
          <Footer.BtnBox>
            <Footer.Btn
              onClick={previewHandler}
              bgColor={"#2db9c2"}
              fontColor={"white"}
            >
              미리보기
            </Footer.Btn>
            <Footer.Btn
              onClick={uploadHandler}
              bgColor={"#4253f2"}
              fontColor={"white"}
            >
              저장
            </Footer.Btn>
          </Footer.BtnBox>
        </Footer.Container>
      </div>
      <div>
        {isOpen && (
          <ReleaseNoteModal data={previewData} setIsOpen={setIsOpen} />
        )}
      </div>
    </Wrapper>
  );
};

export default ReleaseNoteUpload;
