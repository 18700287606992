import { ExcelDownloadBtn } from "components/Buttons/ExcelDownloadBtn";
import { ListDefault } from "components/ListDefault/ListDefault";
import { List, Wrapper } from "template/ListTemplate.style";
import paymentPayColumn from "../paymentPayColumn.json";
import PageHandler from "components/PageHandler/PageHandler";
import { useExcelDownload } from "hooks/excelDownload/useExcelDownload";
import { PAYMENT_PAY_XLSX_DOWNLOAD } from "constants/xlsxDownloadConstant";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useToastCustom } from "hooks/useToastCustom";
import { POST_FIND_PAYMENT } from "constants/pathConstant";
import { InfoWrapper } from "../PaymentPay.style";
import { useState } from "react";
import PaymentPayDetailList from "./PaymentPayDetailList";
import EmptyScreen from "components/EmptyScreen/EmptyScreen";

// 결제 내역 :: 결제 내역 리스트 (http://localhost:3000/super/payment/pay)
const PaymentPayDetail = ({
  setPage,
  setSize,
  paymentPayList,
  searchData,
  isSearch,
}) => {
  const toast = useToastCustom();
  const excelDownload = useExcelDownload();

  const [cancelBg, setCancelBg] = useState(false);

  const { mutate } = useMutationCustom(POST_FIND_PAYMENT);

  const handleExcelDownload = () => {
    if (!isSearch || !paymentPayList.list[0]?.cnt) {
      toast("다운로드할 데이터가 없습니다", "error");
      return;
    }

    const data = {
      ...searchData,
      pageSize: paymentPayList.list[0]?.cnt,
      currentPage: 1,
    };

    mutate(data, {
      onSuccess: (response) => {
        const excelData = {
          data: response.list,
          headers: PAYMENT_PAY_XLSX_DOWNLOAD.header,
          fileName: PAYMENT_PAY_XLSX_DOWNLOAD.filename,
        };

        excelDownload(excelData);
      },

      onError: () => {
        toast("전체 데이터 조회 실패", "error");
      },
    });
  };

  return (
    <Wrapper>
      <List.TitleContainer>
        <List.Text>페이 결제내역 목록</List.Text>
        <List.BtnContainer>
          {/* 페이지 핸들러 */}
          <PageHandler setPage={setPage} setPageSize={setSize} />
          {/* 엑셀 다운로드 버튼 */}
          <ExcelDownloadBtn onClick={handleExcelDownload} />
        </List.BtnContainer>
      </List.TitleContainer>

      <List.Container>
        {!isSearch && <ListDefault text={"결제내역을"} />}
        {isSearch && !paymentPayList.list.length && <EmptyScreen />}
        {isSearch && !!paymentPayList.list.length && (
          <>
            <List.Content>
              {paymentPayColumn.column.map((v, idx) => (
                <List.Row $ratio={v.ratio} key={idx}>
                  {v.title}
                </List.Row>
              ))}
            </List.Content>
            <InfoWrapper cancelBg={cancelBg}>
              {paymentPayList.list.map((v, idx) => (
                <PaymentPayDetailList
                  key={idx}
                  data={v}
                  setCancelBg={setCancelBg}
                />
              ))}
            </InfoWrapper>
          </>
        )}
      </List.Container>
    </Wrapper>
  );
};

export default PaymentPayDetail;
