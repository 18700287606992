export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ETH_SCAN_TX = process.env.REACT_APP_ETH_SCAN_TX;
export const POLYGON_SCAN_TX = process.env.REACT_APP_POLYGON_SCAN_TX;
export const BTC_SCAN_TX = process.env.REACT_APP_BTC_SCAN_TX;
export const SYMBOL = "SKETCH";
export const IMG_CDN_URL = "https://cdn.sketchproject.io";

export const HTTP_METHOD = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

// auth
export const POST_LOGIN = "/api/v1/auth/login";
export const POST_SIGNUP = "/api/v1/auth/signup";
export const POST_REISSUE = "/api/v1/auth/reissue";
export const POST_ADMIN_ME = "/api/v1/admin/me";
export const PUT_CHANGE_ADMIN_ROLE = "/api/v1/admin/role";

// manager search
export const POST_ADMIN_LIST = "/api/v1/admin";

// charge
// 입금 리스트
export const POST_SWAP_LIST = "/api/v1/swap/getSwapList";

// withdraw
// 출금 리스트
export const POST_WITHDRAW_LIST = "/api/v1/withdraw/getWithdrawList";
// 인출 (충전) 재시도
export const POST_RETRY_WITHDRAW_CHARGE =
  "/api/v1/withdraw/retryWithdrawUnlock";
// 인출 (리워드) 재시도
export const POST_RETRY_WITHDRAW_REWARD =
  "/api/v1/withdraw/retryWithdrawReward";
// 인출 반려
export const POST_HOLD_WHITDRAW_REWARD = "/api/v1/withdraw/holdWithdrawReward";
// 인출 확인 체크박스
export const POST_CONFIRM_WITHDRAW = "/api/v1/withdraw/withDrawConfirm";

// swap
export const POST_MIGRATION_LIST = "/api/v1/migration/getMigrationList";
// swap 재시도
export const POST_RETRY_MIGRATION = "/api/v1/migration/retryMigration";
// 수동인출
export const POST_WITHDRAW_REWARD_CPL = "/api/v1/withdraw/withDrawRewardCpl";
// 회수
export const POST_SETTLE_LIST = "/api/v1/settle/getSettleList";
// 스왑 상태값 변경
export const POST_CHANGE_SWAP_STATE = "/api/v1/migration/changeMigrationState";
// 스왑 확인 체크박스
export const POST_CONFIRM_SWAP = "/api/v1/migration/sketMigrationConfirm";

//migration
export const POST_MIGRATION_CONFIRM_CHECK =
  "/api/v2/migration/sketMigrationConfirm";
export const POST_MIGRATION_FETCH = "/api/v2/migration/getMigrationList";
export const POST_MIGRATION_STATE_MODIFY =
  "/api/v2/migration/changeMigrationState";

// user
// 유저 리스트 (매니저)
export const POST_FIND_USER_BASIC = "/api/v1/users/searchAcctMemberBasic";
// 유저 리스트 (관리자)
export const POST_FIND_USER_SUPER = "/api/v1/users/searchAcctMember";
// 유저 리스트에서 전화번호 인증
export const POST_VERIFY_CELLNUMBER = "/api/v1/users/verifyCellNumber";
// 유저, 스토어 전화번호 인증 타입
export const VERIFY_TYPE = {
  USER: "acct",
  STORE: "store",
};

// 탈퇴 유저 관리
export const POST_FIND_DROP_USER = "/api/v1/users/searchAcctQuitMember";
// 탈퇴 신청 유저 탈퇴 처리
export const POST_DELETE_USER_ACCT = "/api/v1/users/deleteAcct";
// 탈퇴 신청 유저 반려 처리
export const POST_RESTORE_USER_ACCT = "/api/v1/users/reqAcctQuitRestore";
// 탈퇴 회원 히스토리
export const POST_DROP_USER_HISTORY = "/api/v1/users/reqAcctQuitDetail";
// 탈퇴 회원 메모 수정
export const POST_MEMO_MODIFY = "/api/v1/users/updAcctQuitMemo";
// 탈퇴 회원 밸런스 정보
export const POST_ACCT_QUIT_BALANCE = "/api/v1/users/getReqQuitBalance";
// 회수 처리
export const POST_SETTLE_QUIT_ACCT = "/api/v1/users/settleQuitAcct";
// 언락 처리
export const POST_UNLOCK_QUIT_ACCT = "/api/v1/users/unlockQuitAcct";

// 유저 디테일
export const POST_USER_DETAIL = "/api/v1/users/getAcctDetailInfo";
// 유저 디테일 수정
export const POST_MODIFY_USER_DETAIL = "/api/v1/users/modifyAcctInfo";
// 유저 추천인
export const POST_USER_REFERRAL = "/api/v1/users/recBoard";
// 유저 디테일 로그 (회원, 가맹점, 통화기록 등)
export const POST_USER_LOG = "/api/v1/users/getAcctLogs";
// 유저 디테일 로그 입력
export const POST_INSERT_USER_LOG = "/api/v1/users/insertAcctLog";
// 유저 디테일 로그 수정
export const POST_MODIFY_USER_LOG = "/api/v1/users/modifyAcctMemo";
// 유저 디테일 로그 삭제
export const POST_DELETE_USER_LOG = "/api/v1/users/deleteAcctInfo";

// wallet (user detail)
export const POST_USER_WALLET_LIST = "/api/v1/wallet/getWalletAddrList";
export const POST_WALLET_HISTORY = "/api/v1/wallet/getWalletHistoryList";
export const POST_WALLET_PAYMENT = "/api/v1/wallet/getPaymentListBySendWa";
export const POST_WALLET_PAYMENT_DETAIL = "/api/v1/wallet/getPaymentDetailOne";
export const WALLET_HISTORY_TYPE = {
  SEND: "S",
  GET: "R",
  PAY: "P",
};

// store
export const POST_FIND_STORE_BASIC = "/api/v1/stores/searchStoreBasic";
export const POST_FIND_STORE_SUPER = "/api/v1/stores/searchStoreList";
export const POST_USER_STORE_LIST = "/api/v1/stores/searchStoreListByAcctCd";
export const POST_USER_STORE_DETAIL =
  "/api/v1/stores/searchStoreDetailByAcctCd";
export const STORE_DETAIL_TYPE = {
  STORE_INFO: "storeInfo",
  ATTACH_FILE: "attachFile",
};
export const POST_MODIFY_STORE_DETAIL = "/api/v1/stores/updSubStoreInfoB";
export const POST_MODIFY_STORE_STATE = "/api/v1/stores/updSubStoreStateB";

// navigation
export const NAV_USER_DETAIL_BASE_PAY = "/manage/user/pay/";
export const NAV_USER_DETAIL_BASE_WALLET = "/manage/user/wallet/";

// wallet move
export const POST_FIND_WALLET_MOVE = "/api/v1/wallet/getAssets";
export const POST_FIND_USER_WALLET_MOVE = "/api/v1/wallet/getUserAssets";
export const POST_ASSET_LIST = "/api/v1/asset/getAssetList";

// wallet address
export const POST_WALLET_ADDRESS = "/api/v1/walletAddress/getWalletAddress";

// payment
export const POST_FIND_PAYMENT = "/api/v1/wallet/paymentDetails";
export const POST_FIND_STORE_PAYMENT = "/api/v1/wallet/getStoreListByName";
export const POST_FIND_STORE_DETAIL = "/api/v1/wallet/storeDetails";
export const POST_FIND_PAYMENT_CANCEL = "/api/v1/wallet/paymentCancels";
export const POST_PAYMENT_LIST = "/api/v1/payment/getPaymentList";

// change history (user store detail)
export const POST_CHANGE_HISTORY = "/api/v1/stores/storeChangeHistory";

// sfuel/manage
// 회사 지갑 & 잔액 받아오기
export const GET_SFUEL_COMPANY_WALLET = "/api/v1/sfuel/wallet/company";
// 지갑 개수, sfuel 개수 받아오기
export const GET_SFUEL_BALANCE_TOTAL = "/api/v1/sfuel/balance/total";
// 지갑 생성
export const POST_SFUEL_WALLET_ENROLL = "/api/v1/sfuel/wallets/company";
// 작업자 불러오기
export const GET_SFUEL_WORKERS = "/api/v1/sfuel/workers";
// n개 할당하기
export const POST_SFUEL_WORKERS_ASSIGN = "/api/v1/sfuel/workers/wallets/assign";
// 할당 가능한 지갑 수 불러오기
export const GET_SFUEL_ASSIGNABLE = "/api/v1/sfuel/wallets/assignable";
// 회사 지갑으로 모으기
export const POST_WALLET_COLLECT = "/api/v1/sfuel/wallet/company/collect";

// sfuel/work
// 지갑 리스트 불러오기
export const GET_SFUEL_WORKER_WALLETS = "/api/v1/sfuel/worker/wallets";
// 지갑 밸런스 체크
export const POST_SFUEL_BALANCE = "/api/v1/sfuel/wallets/balance";

// 팝업
export const POST_FIND_POPUP_LIST = "/api/v1/popup/searchListPopups";
export const POST_POPUP_CREATE = "/api/v1/popup/createPopup";
export const POST_POPUP_MODIFY = "/api/v1/popup/modifyPopup";
export const POST_POPUP_DELETE = "/api/v1/popup/deletePopups";
export const POST_POPUP_UPDATE_SHOW = "/api/v1/popup/updatePopupsShow";
export const POST_POPUP_CHANGE_ORDER = "/api/v1/popup/updatePopupOrder";

// 팝업2
export const POST_POPUP2_LIST_FETCH = "/api/v2/popup/searchListPopups";
export const POST_POPUP2_CREATE = "/api/v2/popup/createPopup";
export const POST_POPUP2_MODIFY = "/api/v2/popup/modifyPopup";
export const POST_POPUP2_DELETE = "/api/v2/popup/deletePopups";
export const POST_POPUP2_UPDATE_SHOW = "/api/v2/popup/updatePopupsShow";
export const POST_POPUP2_CHANGE_ORDER = "/api/v2/popup/updatePopupOrder";

// FAQ
export const POST_FAQ_CREATE = "/api/v1/faq/createFaq";
export const POST_FAQ_MODIFY = "/api/v1/faq/modifyFaq";
export const POST_FAQ_PATCH_USE_YN = "/api/v1/faq/patchUseYnFaq";
export const POST_FAQ_DELETE = "/api/v1/faq/deleteFaq";
export const POST_FAQ_FETCH = "/api/v1/faq/getFaqList";

// FAQ2
export const POST_FAQ2_CREATE = "/api/v2/faq/createFaq";
export const POST_FAQ2_MODIFY = "/api/v2/faq/modifyFaq";
export const POST_FAQ2_PATCH_USE_YN = "/api/v2/faq/patchUseYnFaq";
export const POST_FAQ2_DELETE = "/api/v2/faq/deleteFaq";
export const POST_FAQ2_FETCH = "/api/v2/faq/getFaqList";
export const POST_FAQ2_FETCH_CATEGORY = "/api/v2/faq/getFaqCategory";

// 공지
export const POST_NOTICE_CREATE = "/api/v1/notice/createNotice";
export const POST_NOTICE_MODIFY = "/api/v1/notice/modifyNotice";
export const POST_NOTICE_PATCH_USE_YN = "/api/v1/notice/patchUseYnNotice";
export const POST_NOTICE_DELETE = "/api/v1/notice/deleteNotice";
export const POST_NOTICE_FETCH = "/api/v1/notice/getNoticeList";

// 공지2
export const POST_NOTICE2_CREATE = "/api/v2/notice/createNotice";
export const POST_NOTICE2_MODIFY = "/api/v2/notice/modifyNotice";
export const POST_NOTICE2_PATCH_USE_YN = "/api/v2/notice/patchUseYnNotice";
export const POST_NOTICE2_DELETE = "/api/v2/notice/deleteNotice";
export const POST_NOTICE2_FETCH = "/api/v2/notice/getNoticeList";

// 버전 관리
export const POST_APP_VERSION_CREATE = "/api/v1/manage/createAppVersion";
export const POST_APP_VERSION_MODIFY = "/api/v1/manage/modifyAppVersion";
export const POST_APP_VERSION_PATCH_USE_YN =
  "/api/v1/manage/patchUseYnAppVersion";
export const POST_APP_VERSION_FETCH = "/api/v1/manage/getAppVersions";

// 버전 관리 2
export const POST_APP_VERSION2_CREATE = "/api/v2/manage/createAppVersion";
export const POST_APP_VERSION2_MODIFY = "/api/v2/manage/modifyAppVersion";
export const POST_APP_VERSION2_PATCH_USE_YN =
  "/api/v2/manage/patchUseYnAppVersion";
export const POST_APP_VERSION2_FETCH = "/api/v2/manage/getAppVersions";
export const POST_APP_VERSION2_PATCH_SERVER_UPDATE_YN =
  "/api/v2/manage/patchServerUpdateVersion";

// 코인 관리
export const POST_COIN_FETCH = "/api/v1/coin/getCoinTickerList";
export const POST_COIN_MODIFY = "/api/v1/coin/updateCoinTicker";
export const POST_COIN_CREATE = "/api/v1/coin/createCoinTicker";

// 메인
export const POST_TODO_LIST_FETCH = "/api/v1/main/getTodoList";
export const POST_MAIN_PROPERTY_FETCH = "/api/v2/main/getCorpWaList";
export const POST_MAIN_REGISTRATION_FETCH = "/api/v1/main/recentSignups";
export const POST_MAIN_PAYMENT_FETCH = "/api/v1/main/paymentSummary";
export const POST_MAIN_PAYMENT_GRAPH_FETCH = "/api/v1/main/paymentSummaryGraph";
export const POST_MAIN_BRIDGE_FETCH = "/api/v1/main/bridgeSummary";
export const POST_MAIN_BRIDGE_GRAPH_FETCH = "/api/v1/main/bridgeSummaryGraph";
export const POST_MAIN_SCHEDULE_CREATE = "/api/v1/main/createCalendar";
export const POST_MAIN_SCHEDULE_FETCH = "/api/v1/main/getCalendarList";
export const POST_MAIN_SCHEDULE_FETCH_COUNT =
  "/api/v1/main/getCalendarCountList";
export const POST_MAIN_SCHEDULE_MODIFY = "/api/v1/main/modifyCalendar";
export const POST_MAIN_SCHEDULE_DELETE = "/api/v1/main/deleteCalendar";
export const POST_MAIN_SCHEDULE_SEARCH_ADMIN =
  "/api/v1/main/tagAdminToCalendarEvent";

// sketch drop
export const POST_SKETCH_DROP_FETCH = "/api/v2/drop/sketchDropList";
export const POST_SKETCH_DROP_DETAIL = "/api/v2/drop/sketchDropDetailedList";
export const POST_SKETCH_DROP_DETAIL_SHEETS =
  "/api/v2/drop/sketchDropDetailedSplitList";

// manage ui
export const POST_MANAGE_UI_CREATE = "/api/v1/sketch-ui/createSketchUi";
export const POST_MANAGE_UI_MODIFY = "/api/v1/sketch-ui/modifySketchUi";
export const POST_MANAGE_UI_DELETE = "/api/v1/sketch-ui/deleteSketchUi";
export const POST_MANAGE_UI_FETCH = "/api/v1/sketch-ui/getSketchUiList";

// sketch back
export const POST_SKETCH_BACK_FETCH = "/api/v1/sketchback/getSketchBackList";
export const POST_SKETCH_BACK_DETAIL =
  "/api/v1/sketchback/getSketchBackDetailList";
export const POST_SKETCH_BACK_EXCLUDE = "/api/v1/sketchback/excludeSketchBack";
export const POST_SKETCH_BACK_BATCH_EXECUTE = "/api/v1/sketchback/batchExecute";
export const POST_SKETCH_BACK_FAIL_EXECUTE = "/api/v1/sketchback/failExecute";

// wallet user
export const POST_WALLET_USER_NAME_FETCH =
  "/api/v1/member/getWalletMemberNameList";
export const POST_WALLET_USER_BASIC_FETCH =
  "/api/v1/member/searchWalletMemberBasic";
export const POST_WALLET_USER_ADMIN_FETCH = "/api/v1/member/searchWalletMember";
export const POST_WALLET_USER_VERIFY_MOBILE = "/api/v1/member/verifyMobile";
export const POST_WALLET_USER_FETCH_INFO = "/api/v1/member/getMemberDetailInfo";
export const POST_WALLET_USER_RECOMMEND_INFO = "/api/v1/member/recMembers";

// 회원 상세 지갑 정보
export const POST_WALLET_INFO_SUMMARY = "/api/v1/member/getAssetsInfo";
export const POST_WALLET_INFO_PAYMENT = "/api/v1/member/getPaymentInfo";
export const POST_WALLET_INFO_DROP = "/api/v1/member/getSketchDropInfo";
export const POST_WALLET_INFO_BACK = "/api/v1/member/getSketchBackInfo";

// 월렛 탈퇴 회원
export const POST_WALLET_DELETE_MEMBER_FETCH =
  "/api/v1/member/searchWalletDeleteMember";

// 월렛 월별 결제내역
export const POST_PAYMENT_MONTHLY_FETCH = "/api/v1/payment/getMonthlyPayment";
export const POST_PAYMENT_MONTHLY_DETAIL =
  "/api/v1/payment/getMonthlyPaymentDetailList";

// 릴리즈 노트
export const POST_RELEASE_CATEGORY_INFO = "/api/v1/release/getInfos";
export const POST_RELEASE_NOTE_ALL = "/api/v1/release/getNotes";
export const POST_RELEASE_NOTE_LATEST = "/api/v1/release/getLatest";
export const POST_RELEASE_NOTE_CREATE = "/api/v1/release/postNote";
export const POST_RELEASE_NOTE_MODIFY = "/api/v1/release/putNote";
export const POST_RELEASE_NOTE_DELETE = "/api/v1/release/deleteNote";
