import { Note } from "../style";

const ReleaseTagContents = ({ data, setIsOpen, setSelectedTagData }) => {
  const handleTagModal = () => {
    setIsOpen(true);
    setSelectedTagData(data);
  };

  return (
    <Note.TagContents>
      <Note.TagTitle onClick={handleTagModal}>
        <p>{`v${data.version}${
          data.versionIos ? ` / v${data.versionIos}` : ""
        }`}</p>
      </Note.TagTitle>
      <Note.TagInfo>
        <span>{data.updatedAt.slice(0, 10)}</span>
        <span>{data.commitHash}</span>
        <span>{data.modifiedBy}</span>
      </Note.TagInfo>
    </Note.TagContents>
  );
};

export default ReleaseTagContents;
