import styled from "styled-components";

export const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(5px);
  -webkit-filter: brightness(65%);
  backdrop-filter: blur(5px);
  position: fixed;
  z-index: 22;
`;

export const ModalContainer = styled.div`
  z-index: 23;
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 2rem;

  & > div {
    width: 50rem;
    height: 66rem;

    & > img {
      width: 100%;
      height: 100%;
    }
  }
`;
