import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > :first-child {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  & > :nth-child(2) {
    width: 100%;
    display: flex;
  }

  & > :nth-child(3) {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }
`;

export const Category = {
  Box: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #cdcdcd;
  `,

  Btn: styled.nav`
    display: flex;
    font-size: 2.2rem;
    gap: 2rem;
  `,

  List: styled.li`
    background: ${(props) => (props.selected ? "#fff" : "none")};
    padding: 1rem 1.5rem;
    border-radius: 0.5rem;
    cursor: pointer;
    list-style-type: none;
  `,
};

export const Note = {
  ReleaseBox: styled.div`
    display: flex;
    flex-direction: column;
    gap: 3rem;
  `,

  BtnBox: styled.div`
    display: flex;
    font-size: 2.2rem;
    padding: 1rem 0;
    cursor: pointer;
  `,

  SearchBox: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,

  SearchBtn: styled.div`
    font-size: 2rem;
    border-radius: 1rem;
    background: #2db9c2;
    color: #fff;
    padding: 1rem 1.5rem;
    cursor: pointer;
  `,

  Btn: styled.p`
    padding: 1rem 1.5rem;
    background: ${(props) => (props.selected ? "#fff" : "#EDEEF6")};
    border: ${(props) =>
      !props.selected ? "1px solid #cdcdcd;" : "1px solid #fff"};
  `,

  ListBox: styled.div`
    display: flex;
    width: 100%;
  `,

  ReleaseInfo: styled.div`
    width: 20%;
    font-size: 1.8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 3rem 0;
    color: #777;

    & > :first-child {
      font-size: 2rem;
      color: #000;
    }

    :nth-child(3) {
      cursor: pointer;
    }
  `,

  ReleaseContentsBox: styled.section`
    width: 80%;
    border: 1px solid #cdcdcd;
    font-size: 1.8rem;
    border-radius: 1rem;
  `,

  ReleaseContents: styled.div`
    padding: 2rem;
    /* border-bottom: 1px solid #cdcdcd; */
  `,

  ReleaseTitleBox: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  `,

  ReleaseAssets: styled.div`
    padding: 2rem;
  `,

  ReleaseTitle: styled.p`
    display: flex;
    font-size: 2.5rem;

    align-items: center;
  `,

  TagTitle: styled.p`
    display: flex;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    cursor: pointer;
  `,

  TagContentsBox: styled.div`
    border: 1px solid #cdcdcd;
    font-size: 1.8rem;
    border-radius: 1rem;
  `,

  TagContents: styled.div`
    padding: 2rem;

    &:not(:last-child) {
      border-bottom: 1px solid #cdcdcd;
    }
  `,

  TagInfo: styled.p`
    display: flex;
    gap: 1rem;

    :not(:first-child) {
      color: #777;
    }

    :nth-child(2) {
      cursor: pointer;
    }
  `,

  HashTag: styled.div`
    display: flex;
    gap: 1rem;
    margin-left: 2rem;

    & > p {
      background: #000;
      color: #fff;
      padding: 0.5rem 1rem;
      border-radius: 3rem;
      display: flex;
      gap: 1rem;
      align-items: center;
      font-size: 1.8rem;
    }
  `,

  ModifyBtnBox: styled.div`
    display: flex;
    gap: 2rem;

    & > div {
      cursor: pointer;
    }
  `,
};
