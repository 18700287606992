import { ModalContainer, ModalWrapper } from "./style";

const Faq2Modal = ({ data, setIsOpen }) => {
  return (
    <>
      <ModalWrapper onClick={() => setIsOpen((prev) => !prev)} />
      <ModalContainer>
        <div>
          <div>{data.questionKr}</div>
          <div dangerouslySetInnerHTML={{ __html: data.answerKr }}></div>
        </div>
        <div>
          <div>{data.questionEng}</div>
          <div dangerouslySetInnerHTML={{ __html: data.answerEng }}></div>
        </div>
      </ModalContainer>
    </>
  );
};

export default Faq2Modal;
