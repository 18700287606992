const { default: styled } = require("styled-components");

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > div {
    width: 90%;
    font-size: 2.2rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }
`;

export const Option = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
  `,

  Box: styled.div`
    display: flex;
    gap: 2rem;
    align-items: center;
  `,

  DetailBox: styled.div`
    display: flex;
    align-items: center;

    > p {
      background: #e3e3e3;
      padding: 1rem;
      border-radius: 1rem 0 0 1rem;
    }
  `,

  Select: styled.select`
    padding: 1rem;
    border-radius: 0 1rem 1rem 0;
    border: none;

    > option {
      width: 20rem;
    }
  `,

  Input: styled.input`
    padding: 1rem;
    border-radius: 0 1rem 1rem 0;
  `,

  HashTag: styled.div`
    display: flex;
    gap: 1rem;
    margin-left: 2rem;

    & > div {
      background: #000;
      color: #fff;
      padding: 1rem 1.3rem;
      border-radius: 3rem;
      display: flex;
      gap: 1rem;
      align-items: center;

      > p {
        margin-top: 0.3rem;
        line-height: 0.5;
      }

      > div {
        width: 2rem;
        height: 2rem;

        > img {
          width: 100%;
          height: 100%;
        }
      }
    }
  `,
};

export const Title = styled.p`
  font-size: 4rem;
  font-weight: 900;
  padding-top: 10rem;
`;

export const Footer = {
  Container: styled.div`
    display: flex;
    justify-content: space-between;
  `,

  BtnBox: styled.div`
    display: flex;
    gap: 2rem;
  `,

  Btn: styled.div`
    background: ${(props) => props.bgColor};
    color: ${(props) => (props.fontColor ? props.fontColor : "black")};
    border: 1px solid #d1d1d1;
    padding: 1.5rem 2rem;
    border-radius: 1rem;
    cursor: pointer;
  `,
};
