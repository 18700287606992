import { POST_RELEASE_NOTE_DELETE } from "constants/pathConstant";
import { Note } from "../style";
import { useToastCustom } from "hooks/useToastCustom";
import { useMutationCustom } from "hooks/useMutationCustom";
import { useNavigate } from "react-router";

const ReleaseNoteContents = ({ data, fetchNoteList, setIsOpen }) => {
  const toast = useToastCustom();
  const nav = useNavigate();

  const navToGit = (e) => {
    if (!data.releaseInfo.repositoryName) return;
    e.stopPropagation();
    window.open(`${data.releaseInfo.repositoryName}/${data.commitHash}`);
  };

  const handlerModify = () => {
    nav("/super/system/release-modify", { state: { data } });
  };

  const { mutate: mutateDelete } = useMutationCustom(POST_RELEASE_NOTE_DELETE);

  const handlerDelete = (releaseNoteId) => {
    if (window.confirm("삭제하시겠습니까?")) {
      mutateDelete(
        { releaseNoteId },
        {
          onSuccess: () => {
            fetchNoteList();
            if (setIsOpen) {
              setIsOpen(false);
            }
            toast("삭제가 완료되었습니다.", "success");
          },

          onError: () => {
            toast("삭제에 실패하였습니다.", "error");
          },
        }
      );
    }
  };

  return (
    <Note.ListBox>
      <Note.ReleaseInfo>
        <p>{data.updatedAt.slice(0, 10)}</p>
        <p>{data.modifiedBy}</p>
        <p onClick={(e) => navToGit(e)}>{data.commitHash}</p>
      </Note.ReleaseInfo>
      <Note.ReleaseContentsBox>
        <Note.ReleaseContents>
          <Note.ReleaseTitleBox>
            <Note.ReleaseTitle>
              <p>{`v${data.version}${
                data.versionIos ? ` / v${data.versionIos}` : ""
              }`}</p>
              <Note.HashTag>
                {data.hashTags.map((v) => (
                  <p>{v}</p>
                ))}
              </Note.HashTag>
            </Note.ReleaseTitle>
            <Note.ModifyBtnBox>
              <div onClick={handlerModify}>
                <img
                  src="https://cdn.sketchproject.io/file/828ae6f6modifyBtn.svg"
                  alt="modify_btn"
                />
              </div>
              <div onClick={() => handlerDelete(data.id)}>
                <img
                  src="https://cdn.sketchproject.io/file/1aa1c67edeleteBtn.svg"
                  alt="delete_btn"
                />
              </div>
            </Note.ModifyBtnBox>
          </Note.ReleaseTitleBox>
          <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
        </Note.ReleaseContents>
        {/* <Note.ReleaseAssets>
          <p>어쩌구</p>
        </Note.ReleaseAssets> */}
      </Note.ReleaseContentsBox>
    </Note.ListBox>
  );
};

export default ReleaseNoteContents;
