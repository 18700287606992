import { useCallback, useEffect, useState } from "react";
import { Note, Wrapper } from "./style";
import { useMutationCustom } from "hooks/useMutationCustom";
import {
  POST_RELEASE_CATEGORY_INFO,
  POST_RELEASE_NOTE_ALL,
  POST_RELEASE_NOTE_LATEST,
} from "constants/pathConstant";
import ReleaseNoteContents from "./components/ReleaseNoteContents";
import { PaginationBox } from "components/Pagination/PaginationBox";
import ReleaseCategories from "./components/ReleaseCategories";
import ReleaseTagContents from "./components/ReleaseTagContents";
import ReleaseNoteModal from "components/Modal/ReleaseNoteModal";
import { useNavigationCustom } from "hooks/useNavigationCustom";

const ReleaseNoteList = () => {
  const buttons = [{ name: "Releases" }, { name: "Tags" }];
  const nav = useNavigationCustom();

  const [selectedPlatform, setSelectedPlatform] = useState("HomePage");
  const [selectedType, setSelectedType] = useState("WEB");
  const [categoryPlatform, setCategoryPlatform] = useState([]);
  const [categoryType, setCategoryType] = useState([]);
  const [selectedNote, setSelectedNote] = useState("Releases");
  const [selectedTagData, setSelectedTagData] = useState({});

  const [noteList, setNoteList] = useState({ list: [], cnt: "" });

  const [currentPage, setCurrentPage] = useState(1);
  const [releaseInfoId, setReleaseInfoId] = useState("1");

  const [isOpen, setIsOpen] = useState(false);

  const { mutate: mutateCategoryInfo } = useMutationCustom(
    POST_RELEASE_CATEGORY_INFO
  );
  const { mutate: mutateNoteAll } = useMutationCustom(POST_RELEASE_NOTE_ALL);
  const { mutate: mutateNoteLatest } = useMutationCustom(
    POST_RELEASE_NOTE_LATEST
  );

  const handleCategoryInfo = useCallback(() => {
    mutateCategoryInfo(
      {},
      {
        onSuccess: (res) => {
          const categoryType = res.list.filter(
            (v) => v.name === selectedPlatform
          );

          const newType = categoryType[0]?.info[0].type;
          const newId = categoryType[0]?.info[0].id;

          setCategoryType(categoryType);
          setSelectedType(newType);
          setReleaseInfoId(newId);
          setCategoryPlatform(res);
        },
        onError: (err) => {
          console.error("Failed to fetch category info:", err);
        },
      }
    );
  }, [selectedPlatform]);

  const fetchNoteList = useCallback(() => {
    const mutation =
      selectedNote === "Releases" ? mutateNoteLatest : mutateNoteAll;

    const data =
      selectedNote === "Releases"
        ? { releaseInfoId }
        : { currentPage, pageSize: 10, releaseInfoId };

    mutation(data, {
      onSuccess: (res) => setNoteList(res),
      onError: (err) => {
        console.error("Failed to fetch note list:", err);
      },
    });
  }, [selectedNote, releaseInfoId, currentPage]);

  useEffect(() => {
    handleCategoryInfo();
  }, [handleCategoryInfo]);

  useEffect(() => {
    fetchNoteList();
  }, [fetchNoteList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedPlatform, selectedType, selectedNote]);

  useEffect(() => {
    setSelectedNote("Releases");
  }, [selectedPlatform, selectedType]);

  return (
    <Wrapper>
      <div>
        <ReleaseCategories
          categoryPlatform={categoryPlatform}
          setSelectedPlatform={setSelectedPlatform}
          selectedPlatform={selectedPlatform}
          categoryType={categoryType}
          setSelectedType={setSelectedType}
          setReleaseInfoId={setReleaseInfoId}
          selectedType={selectedType}
        />

        <Note.SearchBox>
          <Note.BtnBox>
            {buttons.map((v, index) => (
              <Note.Btn
                key={v.name}
                value={v.name}
                onClick={() => setSelectedNote(v.name)}
                selected={v.name === selectedNote}
                style={{
                  borderRadius: index === 0 ? "1rem 0 0 1rem" : "0 1rem 1rem 0",
                  borderRight: index === 0 ? "none" : "",
                  borderLeft: index === 1 ? "none" : "",
                }}
              >
                {v.name}
              </Note.Btn>
            ))}
          </Note.BtnBox>
          <Note.SearchBtn onClick={() => nav("/super/system/release-upload")}>
            작성하기
          </Note.SearchBtn>
        </Note.SearchBox>

        {selectedNote === "Releases" && (
          <Note.ReleaseBox>
            {noteList.list.map((v) => (
              <ReleaseNoteContents data={v} fetchNoteList={fetchNoteList} />
            ))}
          </Note.ReleaseBox>
        )}

        {selectedNote === "Tags" && (
          <>
            {!!noteList.list.length && (
              <Note.TagContentsBox>
                {noteList.list.map((v) => (
                  <ReleaseTagContents
                    data={v}
                    setIsOpen={setIsOpen}
                    setSelectedTagData={setSelectedTagData}
                  />
                ))}
              </Note.TagContentsBox>
            )}
          </>
        )}

        {selectedNote === "Tags" && !!noteList.list.length && (
          <PaginationBox
            totalItemsCount={noteList.cnt}
            page={currentPage}
            setPage={setCurrentPage}
            size={10}
          />
        )}
      </div>

      {isOpen && (
        <ReleaseNoteModal
          data={selectedTagData}
          setIsOpen={setIsOpen}
          fetchNoteList={fetchNoteList}
        />
      )}
    </Wrapper>
  );
};

export default ReleaseNoteList;
