import ReleaseNoteContents from "pages/SystemManage/ReleaseNote/ReleaseNoteList/components/ReleaseNoteContents";
import { ModalContainer, ModalWrapper } from "./style";

const ReleaseNoteModal = ({ data, setIsOpen, fetchNoteList }) => {
  return (
    <>
      <ModalWrapper onClick={() => setIsOpen((prev) => !prev)} />
      <ModalContainer>
        <ReleaseNoteContents
          data={data}
          fetchNoteList={fetchNoteList}
          setIsOpen={setIsOpen}
        />
      </ModalContainer>
    </>
  );
};

export default ReleaseNoteModal;
