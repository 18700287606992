import { useLocation } from "react-router";
import ReleaseNoteUpload from "../ReleaseNoteUpload";

const ReleaseNoteModify = ({ modifyData }) => {
  const location = useLocation();

  return <ReleaseNoteUpload modifyData={location.state.data} />;
};

export default ReleaseNoteModify;
